import React from 'react'
import Base from '../components/Base.js'

function About() {
    return (
        <Base>
            <p>Made by Guyllian Gomez</p>
        </Base>
    )
}

export default About